exports.components = {
  "component---src-pages-cloud-engineering-js": () => import("./../../../src/pages/cloud-engineering.js" /* webpackChunkName: "component---src-pages-cloud-engineering-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-and-automation-js": () => import("./../../../src/pages/data-and-automation.js" /* webpackChunkName: "component---src-pages-data-and-automation-js" */),
  "component---src-pages-experience-design-js": () => import("./../../../src/pages/experience-design.js" /* webpackChunkName: "component---src-pages-experience-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-5-common-pitfalls-in-saas-mvp-development-and-how-to-avoid-them-js": () => import("./../../../src/pages/insights/5-common-pitfalls-in-saas-mvp-development-and-how-to-avoid-them.js" /* webpackChunkName: "component---src-pages-insights-5-common-pitfalls-in-saas-mvp-development-and-how-to-avoid-them-js" */),
  "component---src-pages-insights-an-introduction-to-mvp-development-for-saas-startups-js": () => import("./../../../src/pages/insights/an-introduction-to-mvp-development-for-saas-startups.js" /* webpackChunkName: "component---src-pages-insights-an-introduction-to-mvp-development-for-saas-startups-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-insights-scaling-your-saas-from-mvp-to-full-product-js": () => import("./../../../src/pages/insights/scaling-your-saas-from-mvp-to-full-product.js" /* webpackChunkName: "component---src-pages-insights-scaling-your-saas-from-mvp-to-full-product-js" */),
  "component---src-pages-mobile-apps-js": () => import("./../../../src/pages/mobile-apps.js" /* webpackChunkName: "component---src-pages-mobile-apps-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */),
  "component---src-pages-why-pathika-js": () => import("./../../../src/pages/why-pathika.js" /* webpackChunkName: "component---src-pages-why-pathika-js" */),
  "component---src-pages-work-case-studies-azure-multi-agent-architecture-for-private-equity-fund-data-processing-js": () => import("./../../../src/pages/work/case-studies/azure-multi-agent-architecture-for-private-equity-fund-data-processing.js" /* webpackChunkName: "component---src-pages-work-case-studies-azure-multi-agent-architecture-for-private-equity-fund-data-processing-js" */),
  "component---src-pages-work-case-studies-sql-to-nosql-database-redesign-for-iot-data-processing-in-food-facilities-js": () => import("./../../../src/pages/work/case-studies/sql-to-nosql-database-redesign-for-iot-data-processing-in-food-facilities.js" /* webpackChunkName: "component---src-pages-work-case-studies-sql-to-nosql-database-redesign-for-iot-data-processing-in-food-facilities-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

